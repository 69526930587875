//
// Elements
// Settings - Tools - Generic - ELEMENTS - Objects - Components - Vendors - Utilities
//

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	display: flex;
	height: 100%;
	flex-direction: column;
	padding: 0;
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// stylelint-disable selector-max-specificity
// stylelint-disable selector-max-id
// stylelint-disable scale-unlimited/declaration-strict-value
#portal-notification {
	position: fixed;
	z-index: 9999;
}
// stylelint-enable selector-max-specificity
// stylelint-enable selector-max-id
// stylelint-enable scale-unlimited/declaration-strict-value
